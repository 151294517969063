import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import { reduceMultiProperties } from "../utils"

const OrdersContext = createContext()

const OrdersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState({
    next: null,
    previous : null,
    count: 0,
    results: [],
    current_page: 0,
    total_pages:0
  })
  const [order, setOrder] = useState({
    order_note: null,
    order_line_items: [],
    shipping_address: {},
    total_amount_paid: 0,
    total_cost_price: 0
  })
  const [orderNotes, setOrderNotes] = useState({ next: null, results: [], })
  const [carriers, setCarriers] = useState([])
  const [rates, setRates] = useState([])

  const getOrders = config => {
    // setIsLoading(true)
    API.getOrders(config)
      .then(response => {
        const { next, results,previous,count,current_page,total_pages} = response.data.data
        setOrders({ next, results,previous,count,current_page,total_pages  })
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getOrder = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getOrder(id)
        .then(response => {
          const { order_line_items } = response.data.data
          setOrder({
            ...response.data.data,
            order_line_items: [
              ...response.data.data.order_line_items,
              {},
              reduceMultiProperties(order_line_items)
            ]
          })
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const createManualOrder = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.createManualOrder(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const raiseOrderRequest = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.raiseOrderRequest(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getOrderNotes = id => {
    setIsLoading(true)
    API.getOrderNote(id)
      .then(response => {
        const { next, results } = response.data.data
        setOrderNotes({ next, results })
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const addOrderNote = payload => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.addOrderNote(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateOrderNote = (id, payload) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.editOrderNote(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const deleteOrderNote = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.deleteOrderNote(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const syncOrders = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.syncOrders()
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getOrderSummary = () => {
    setIsLoading(true)
    API.getOrderSummary()
      .then(response => {
        console.log(response.data.data)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const downloadInvoice = id => {
    setIsLoading(true)
    API.downloadInvoice(id, { responseType: "arraybuffer" })
      .then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        )
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("target", "_blank")
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getCarriers = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getCarriers()
        .then(response => {
          setCarriers(
            response.data.data.map(({ name, code }) => ({
              label: name,
              value: code
            }))
          )
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getRates = (orderId, config) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getRates(orderId, config)
        .then(response => {
          setRates(
            response.data.data.map(
              ({ serviceName, serviceCode, total_cost }) => ({
                label: serviceName,
                value: serviceCode,
                total_cost: total_cost
              })
            )
          )
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  return (
    <OrdersContext.Provider
      value={{
        isLoading,
        orders,
        getOrders,
        order,
        getOrder,
        raiseOrderRequest,
        createManualOrder,
        getOrderNotes,
        addOrderNote,
        updateOrderNote,
        deleteOrderNote,
        syncOrders,
        getOrderSummary,
        downloadInvoice,
        carriers,
        getCarriers,
        rates,
        getRates
      }}
    >
      {children}
    </OrdersContext.Provider>
  )
}

export const useOrdersContext = () => useContext(OrdersContext)

export default OrdersProvider
