import { Link } from "react-router-dom"

export const column = [
  {
    field: "name",
    headerName: "Product",
    flex: 1,
    minWidth: 280,
    cellRenderer: ({ value }) => (
      <Link className={value} style={{ color: "#27963C" }}>
        {value}
      </Link>
    )
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
    minWidth: 160
  },
  {
    field: "price",
    headerName: "Price Each",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "price_total",
    headerName: "Price Total",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "cost_price",
    headerName: "Cost Each",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
  {
    field: "cost_price_total",
    headerName: "Cost Total",
    flex: 1,
    minWidth: 160,
    cellRenderer: ({ value }) => {
      if (value) return <span>{`$ ${Number(value).toFixed(2)}`}</span>
    }
  },
]
