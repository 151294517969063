import Card from "react-bootstrap/Card"
import { Col, Form, Row } from "react-bootstrap"
import { assets, stringToHTML } from "../../../utils"
import { CustomButton } from "../../../components/CustomButton"
import "./product.scss"

export function Product({
  product,
  product_catalog_images = [],
  product_catalog_variants = [],
  if_present_in_user_store = false,
  in_stock = false,
  synced_products=false,
  my_in_stock_status,
  title,
  body_html,
  onClick = () => {},
  onClickAdd = () => {},
  onClickRemove = () => {},
  onChangeHandle = () => {},
  selectedProducts,
  setSelectedProducts,
  isLoading,
  myProducts,
  bestsellers = false
}) {
  return (
    <Card className="product position-relative">
      <Card.Img
        variant="top"
        src={product_catalog_images?.[0]?.src || assets.DefaultProductIcon}
        style={{
          maxWidth: "100%",
          height: "auto",
          aspectRatio: "1/1",
          cursor: "pointer"
        }}
        onClick={onClick}
      />
      <Card.Body>
        <Row>
          <Col xs="10">
            <Card.Title
              as="h6"
              className="title"
              style={{ cursor: "pointer" }}
              onClick={onClick}
            >
              {title}
            </Card.Title>
          </Col>
          {!bestsellers && (
            <Col xs="2">
              {in_stock && !if_present_in_user_store && (
                <Form.Check
                  key="out-of-stock"
                  type="checkbox"
                  checked={selectedProducts?.includes(product)}
                  disabled={isLoading}
                  onClick={e => {
                    e.stopPropagation()
                    onChangeHandle(e.target.checked)
                    setSelectedProducts(prevState => {
                      if (!prevState.includes(product)) {
                        return [...prevState, product]
                      }
                      return prevState.filter(item => item !== product)
                    })
                  }}
                />
              )}
              {myProducts && (
                <Form.Check
                  key="out-of-stock"
                  type="checkbox"
                  checked={selectedProducts?.includes(product)}
                  disabled={isLoading}
                  onClick={e => {
                    e.stopPropagation()
                    onChangeHandle(e.target.checked)
                    setSelectedProducts(prevState => {
                      if (!prevState.includes(product)) {
                        return [...prevState, product]
                      }
                      return prevState.filter(item => item !== product)
                    })
                  }}
                />
              )}
            </Col>
          )}
        </Row>
        {body_html && (
          <Card.Text className="body_html">{stringToHTML(body_html)}</Card.Text>
        )}
        <Row className="product-footer">
          <Col xs="7">
            <Card.Text className="selling-price mb-2 text-nowrap">
              {`Cost: $${product_catalog_variants?.[0]?.cost}*`}
            </Card.Text>
          </Col>
          {!bestsellers && (
            <Col xs="5" className="d-flex justify-content-end">
              {if_present_in_user_store ? (
                <CustomButton
                  label="Remove"
                  variant="outline-primary"
                  onClick={onClickRemove}
                  disabled={isLoading}
                />
              ) : (
                <CustomButton
                  label="Add"
                  variant="outline-primary"
                  className={`${in_stock ? "visible" : "invisible"}`}
                  disabled={selectedProducts?.includes(product) || isLoading}
                  onClick={onClickAdd}
                />
              )}
            </Col>
          )}
        </Row>
      </Card.Body>
      {myProducts ? (
        <div className={`stock-chip ${in_stock ? "in-stock" : "out-of-stock"}`}>
          {in_stock ? (
            <span>{my_in_stock_status}</span>
          ) : (
            <span>Out of Stock</span>
          )}
        </div>
      ) : (
        <div className={`stock-chip ${in_stock ? "in-stock" : "out-of-stock"}`}>
          {in_stock ? <span>In-Stock</span> : <span>Out of Stock</span>}
        </div>
      )}
    </Card>
  )
}
