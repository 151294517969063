import { Container, Row, Col } from "react-bootstrap"
import { assets } from "../../utils"
import { CustomAgGridTable } from "../../components/CustomAgGridTable"
import { column } from "./columns"
import { useNavigate, useParams } from "react-router-dom"
import { PaymentCard } from "./paymentCard"
import { ShippingCard } from "./shippingCard"
import { NoteCard } from "./noteCard"
import { useEffect, useState } from "react"
import { useOrdersContext } from "../../contexts/OrdersContext"
import { CustomSpinner } from "../../components/CustomSpinner"
import { useAuthContext } from "../../contexts/AuthContext"
import { InfoPopup } from "../../components/InfoPopup"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { CreditCard } from "../../components/CreditCard"
import { ShippingDetails } from "./shippingdetails"
import { CustomCard } from "../../components/CustomCard"
import { InputField } from "../../components/InputField"
import "./ordersdetails.scss"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import Select from 'react-select';
import { set, useForm } from "react-hook-form"
import { CustomButton } from "../../components/CustomButton"
import { API } from "../../api"
import { CustomModal } from "../../components/CustomModal"

export const OrdersDetails = () => {
  const nav = useNavigate()
  const params = useParams()
  const { notifySuccess } = useAuthContext()
  const { paymentProfiles, getPaymentProfiles, setActiveTab } =
    useUserProfileContext()
  const {
    isLoading,
    order,
    getOrder,
    addOrderNote,
    updateOrderNote,
    raiseOrderRequest,
    deleteOrderNote,
    rates
  } = useOrdersContext()
  const {
    name,
    common_status_descr,
    order_line_items,
    shipping_address,
    order_note,
    total_cost_price,
    service_fee
  } = order

  const [addressEdit, setAddressEdit] = useState(false)
  const [show, setShow] = useState(false)
  const onHide = () => setShow(false)
  const [showShipping, setShowShipping] = useState(false)
  const onHideShipping = () => setShowShipping(false)
  const [shippingFee, setShippingFee] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [shippingDetails, setShippingDetails] = useState({})
  const [shippingMode, setShippingMode] = useState(false)
  const [shippingName, setShippingName] = useState("")
  const [payInProgress, setPayInProgress] = useState(false)

  const [showAddress, setShowAddress] = useState(false)
  const onHideAddress = () => setShowAddress(false)

  const [fullName, setName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address_line1, setAddress_line1] = useState("")
  const [address_line2, setAddress_line2] = useState("")
  const [city, setCity] = useState("")
  const [zipcode, setZipcode] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("United States")
  const [stateList , setStateList] = useState([
    { value: 'AK', label: 'Alaska'},
    { value: 'TX', label: 'Texas'},
    { value: 'AL', label: 'Alabama'},
    { value: 'AR', label: 'Arkansas'},
    { value: 'AZ', label: 'Arizona'},
    { value: 'CA', label: 'California'},
    { value: 'CO', label: 'Colorado'},
    { value: 'CT', label: 'Connecticut'},
    { value: 'DC', label: 'District of Columbia'},
    { value: 'DE', label: 'Delaware'},
    { value: 'FL', label: 'Florida'},
    { value: 'GA', label: 'Georgia'},
    { value: 'HI', label: 'Hawaii'},
    { value: 'IA', label: 'Iowa'},
    { value: 'ID', label: 'Idaho'},
    { value: 'IL', label: 'Illinois'},
    { value: 'IN', label: 'Indiana'},
    { value: 'KS', label: 'Kansas'},
    { value: 'KY', label: 'Kentucky'},
    { value: 'LA', label: 'Louisiana'},
    { value: 'MA', label: 'Massachusetts'},
    { value: 'MD', label: 'Maryland'},
    { value: 'ME', label: 'Maine'},
    { value: 'MI', label: 'Michigan'},
    { value: 'MN', label: 'Minnesota'},
    { value: 'MO', label: 'Missouri'},
    { value: 'MS', label: 'Mississippi'},
    { value: 'MT', label: 'Montana'},
    { value: 'NC', label: 'NorthCarolina'},
    { value: 'ND', label: 'NorthDakota'},
    { value: 'NE', label: 'Nebraska'},
    { value: 'NH', label: 'NewHampshire'},
    { value: 'NJ', label: 'NewJersey'},
    { value: 'NM', label: 'NewMexico'},
    { value: 'NV', label: 'Nevada'},
    { value: 'NY', label: 'NewYork'},
    { value: 'OH', label: 'Ohio'},
    { value: 'OK', label: 'Oklahoma'},
    { value: 'OR', label: 'Oregon'},
    { value: 'PA', label: 'Pennsylvania'},
    { value: 'RI', label: 'RhodeIsland'},
    { value: 'SC', label: 'SouthCarolina'},
    { value: 'SD', label: 'SouthDakota'},
    { value: 'TN', label: 'Tennessee'},
    { value: 'TX', label: 'Texas'},
    { value: 'UT', label: 'Utah'},
    { value: 'VA', label: 'Virginia'},
    { value: 'VT', label: 'Vermont'},
    { value: 'WA', label: 'Washington'},
    { value: 'WI', label: 'Wisconsin'},
    { value: 'WV', label: 'WestVirginia'},
    { value: 'WY', label: 'Wyoming'}
  ])
  const [editMode, setEditMode] = useState(false)

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .min(3, "First Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid First Name"),
    lastName: Yup.string()
      .required("Last Name is required")
      .min(3, "Last Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid Last Name"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    address_line1: Yup.string().required("Address line 1 is required"),
    // address_line2: Yup.string().required("Address line 2 is required"),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string().required("Zip code is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { control, trigger, setValue, handleSubmit } = useForm(formOptions)

  const onClickPay = () => {
    setPayInProgress(true)
    raiseOrderRequest(shippingDetails)
      .then(() => {
        setShow(false)
        setPayInProgress(false)
        setShippingMode(true)
        notifySuccess("Amount paid successfully!")
        getOrder(params.id)
      })
      .catch(err => setPayInProgress(false))
  }

  const onSubmit = vals => {
    const { total_cost_price, service_fee } = order
    const { shipping_carrier, shipping_code } = vals
    const { total_cost, serviceName } = rates.find(
      ({ value }) => value === shipping_code
    )
    setShippingFee(total_cost)
    setShippingName(serviceName)
    setTotalAmount(
      Number(total_cost_price) + Number(service_fee) + Number(total_cost)
    )
    setShippingDetails({ order: params.id, shipping_carrier, shipping_code })
    setShippingMode(true)
    setShowShipping(false)
  }

  const onSubmitAddress = vals => {
    setEditMode(true)
    const payload = {
      order_id: params.id,
      shipping_address :{
        name:firstName + " " + lastName,
        first_name:firstName,
        last_name:lastName,
        email,
        address1:address_line1,
        address2:address_line2,
        city,
        zip:zipcode,
        country_code: "US",
        state: state?.label,
        province_code: state?.value,
        country
      },
    }
    API.orderAddressChange(payload)
        .then(response => {
          notifySuccess("Order Address Updated Successfully")
          
        })
        .catch(error => {

        })
        .finally(() => {
          setEditMode(false)
          onHideAddress()
        })
  }

  useEffect(() => {
    if (order?.id) {
      const {
        shipping_fee,
        total_amount_paid,
        total_cost_price,
        service_fee,
        shipping_name
      } = order
      setShippingFee(shipping_fee)
      setShippingName(shipping_name)
      if (total_amount_paid > 0) {
        setTotalAmount(total_amount_paid)
      } else {
        setTotalAmount(Number(total_cost_price) + Number(service_fee))
      }
    }
  }, [order])

  useEffect(() => {
    if (params?.id) {
      getOrder(params.id)
    }
  }, [params?.id])

  useEffect(() => {
    if(shipping_address){
      setFirstName(shipping_address.first_name)
      setLastName(shipping_address.last_name)
      setEmail(shipping_address.email)
      setPhone(shipping_address.phone)
      setAddress_line1(shipping_address.address1)
      setAddress_line2(shipping_address.address2)
      setCity(shipping_address.city)
      setZipcode(shipping_address.zip)
      if(shipping_address.province){
        setState({ value: shipping_address.province_code, label: shipping_address.province })
      }else{
        setState({ value: shipping_address.province_code, label: shipping_address.state })
      }
      

      setValue('firstName', shipping_address.first_name)
      setValue('lastName', shipping_address.last_name)
      setValue('email', shipping_address.email)
      setValue('phone', shipping_address.phone)
      setValue('address_line1', shipping_address.address1)
      setValue('address_line2', shipping_address.address2)
      setValue('city', shipping_address.city)
      setValue('zipcode', shipping_address.zip)
      if(shipping_address.province){
        setValue('state', { value: shipping_address.province_code, label: shipping_address.province })
      }else{
        setValue('state', { value: shipping_address.province_code, label: shipping_address.state })
      }
      
      setValue('country', shipping_address.country)
    }
    if(common_status_descr != 'Unpaid'){
      setEditMode(true)
    }else{
      setEditMode(false)
    }
  }, [shipping_address])

  useEffect(() => {
    getPaymentProfiles({ params: { is_default: true } })
  }, [])

  return (
    <Container className="orders-details-container" fluid>
      <Row className="p-1">
        <Col
          xs="10"
          md="11"
          className="d-flex align-items-center"
          onClick={() => nav("/orders")}
          style={{ cursor: "pointer" }}
        >
          <img src={assets.BackArrowIcon} />
          <h5 className="page-title mb-0 ms-1">{`Order - ${name}`}</h5>
        </Col>
        <Col xs="2" md="1" className="d-flex align-items-center">
          <h5 className="page-title">{common_status_descr}</h5>
        </Col>
      </Row>
      <Row className="p-1 mb-3">
        <CustomAgGridTable
          rowData={order_line_items}
          columnDefs={column}
          pagination={false}
          onRowClicked={({ data: { original_product } }) => {
            if (original_product) {
              nav(`/browse-products/${original_product}`)
            }
          }}
        />
      </Row>
      
      <Row className="mb-3"></Row>
      <Row className="mb-1">
        <Col md="3" xs="12" className="mb-3">
          <PaymentCard
            status={common_status_descr}
            total_cost_price={total_cost_price}
            total_amount_paid={totalAmount}
            service_fee={service_fee || null}
            shipping_fee={shippingFee}
            onClick={() => setShow(true)}
            shippingMode={shippingMode}
            onClickSelectMode={() => setShowShipping(true)}
          />
        </Col>
        <Col md="3" xs="12" className="mb-3">
          <ShippingCard {...shipping_address} shippingName={shippingName} addressEdit={showAddress} setAddressEdit={setShowAddress}/>
        </Col>
        <Col md="6" xs="12" className="mb-3">
          <NoteCard
            key={params.id}
            order_note={order_note}
            order={params.id}
            addOrderNote={addOrderNote}
            updateOrderNote={updateOrderNote}
            getOrder={getOrder}
            deleteOrderNote={deleteOrderNote}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <InfoPopup
        size="md"
        show={show}
        onHide={onHide}
        title="Payment Confirmation"
        subTitle={
          <Row className="justify-content-center">
            <Col lg="8" xl="8" xxl="8">
              <CreditCard {...paymentProfiles[0]} isLoading={isLoading} />
            </Col>
          </Row>
        }
        btnTitle="Confirm Payment"
        isLoading={payInProgress}
        onClick={onClickPay}
        onClickLink={() => {
          setActiveTab("billing")
          nav("/settings")
        }}
      />
      <ShippingDetails
        show={showShipping}
        onHide={onHideShipping}
        orderId={params.id}
        onSubmit={onSubmit}
      />
      <CustomSpinner loading={isLoading} />
      <CustomModal
        size={'lg'}
        showHeader={true}
        headerTitle={'Edit Shipping Details'}
        show={showAddress}
        onHide={onHideAddress}
      >
        <form onSubmit={handleSubmit(onSubmitAddress)}>
            <CustomCard title="" className="shipping-info ">
                    <Row>
                      <Col xs="6" className="mb-1">
                          <InputField
                            label={'Customer First Name'}
                            name={'firstName'}
                            type={'text'}
                            value={firstName}
                            onChange={e => {
                              setFirstName(e.target.value)
                              setValue('firstName', e.target.value)
                            }}
                            placeholder={'Customer First Name'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Customer First Name is required!" }}
                          />
                      </Col>
                      <Col xs="6" className="mb-1">
                          <InputField
                            label={'Customer Last Name'}
                            name={'lastName'}
                            type={'text'}
                            value={lastName}
                            onChange={e => {
                              setLastName(e.target.value)
                              setValue('lastName', e.target.value)
                            }}
                            placeholder={'Customer Last Name'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Customer Last Name is required!" }}
                          />
                      </Col>
                      <Col xs="6" className="mb-1">
                          <InputField
                            label={'Customer Email'}
                            name={'email'}
                            type={'text'}
                            value={email}
                            onChange={e => {
                              setEmail(e.target.value)
                              setValue('email', e.target.value)
                            }}
                            placeholder={'Customer Email'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Customer Email is required!" }}
                          />
                      </Col>
                      <Col xs="6" className="mb-1">
                          <InputField
                            label={'Customer Phone'}
                            name={'phone'}
                            type={'text'}
                            value={phone}
                            onChange={e => {
                              setPhone(e.target.value)
                              setValue('phone', e.target.value)
                            }}
                            placeholder={'Customer Phone'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Customer Phone is required!" }}
                          />
                      </Col>
                      
                      
                    </Row>
                    <Row>
                      <Col xs="6" className="mb-1">
                          <InputField
                            label={'Address Line 1'}
                            name={'address_line1'}
                            type={'text'}
                            value={address_line1}
                            onChange={e => {
                              setAddress_line1(e.target.value)
                              setValue('address_line1', e.target.value)
                            }}
                            placeholder={'Address Line 1'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Address Line 1 is required!" }}
                          />
                      </Col>
                      <Col xs="6" className="mb-1">
                          <InputField
                            label={'Address Line 2'}
                            name={'address_line2'}
                            type={'text'}
                            value={address_line2}
                            onChange={e => {
                              setAddress_line2(e.target.value)
                              setValue('address_line2', e.target.value)
                            }}
                            placeholder={'Address Line 2'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Address Line 2 is required!" }}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" className="mb-3">
                          <InputField
                            label={'City'}
                            name={'city'}
                            type={'text'}
                            value={city}
                            onChange={e => {
                              setCity(e.target.value)
                              setValue('city', e.target.value)
                            }}
                            placeholder={'City'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "City is required!" }}
                          />
                      </Col>
                      <Col xs="6" className="mb-3">
                          <InputField
                            label={'Zip'}
                            name={'zipcode'}
                            type={'number'}
                            value={zipcode}
                            onChange={e => {
                              setZipcode(e.target.value)
                              setValue('zipcode', e.target.value)
                            }}
                            placeholder={'Zip'}
                            disabled={editMode}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Zip is required!" }}
                            maxLength={5}
                          />
                      </Col>
                      {state ? <Col xs="6" className="mb-3">
                        <label class="form-label">
                          State
                        </label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          value={state}
                          name="variant"
                          options={stateList}
                          placeholder="Select State"
                          onChange={(selectedOption) => {
                            setState(selectedOption)
                            
                          }}
                        />
                      </Col>:null}
                      <Col xs="6" className="mb-3">
                          <InputField
                            label={'Country'}
                            name={'country'}
                            type={'text'}
                            value={country}
                            onChange={e => {
                              setCountry(e.target.value)
                              setValue('country', e.target.value)
                            }}
                            placeholder={'Country'}
                            disabled={true}
                            control={control}
                            trigger={trigger}
                            rules={{ required: "Country is required!" }}
                          />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" md="3">
                        <CustomButton
                          label="Save Changes"
                          variant="primary"
                          type='submit'
                          disabled={editMode}
                          
                        />
                      </Col>
                    </Row>
            </CustomCard>
          </form>
      </CustomModal>
    </Container>
  )
}
